import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet'; 
import Footer from '../../../components/Biz/Shared/Footer/MainFooter.js'

import '../../../assets/styles/Terms.css';

export default function Cancellation() {

  return (
    <>
      <Helmet>
        <title>BizSolutions | Cancellation Policy</title>
      </Helmet>

      <div className="app-landing-page">
        <Container className='py-3'>
          <Card className="card-parent mx-lg-5 px-lg-5 py-lg-5 mx-3 mx-md-5 my-md-5 px-md-3 py-md-5 py-3 px-1 text-center text-md-start">
              <Card.Body>
                <div className='py-3 text-center'>
                  <h2>BizSolutions LLC</h2>
                  <Card.Title >Cancellation Policy</Card.Title>
                </div>

                <div className='mb-4'>
                  <Card.Subtitle className="mb-2"><p>1. General Policy</p>
                    All service agreements with BizSolutions LLC are final and binding upon purchase completion. Clients may request service cancellations under the terms outlined below. Digital and verbal approvals confirming acknowledgment of this cancellation policy are required after the sale has been made.
                  </Card.Subtitle>
                </div>

                <div className='mb-4'>
                  <Card.Subtitle className="mb-2"><p>2. Notice Requirement</p>
                    <ul>
                        <li>Clients must submit a written cancellation request via email or company portal at least 30 days in advance to avoid penalties.</li>
                        <li>For verbal cancellations, the request must be made via a recorded phone call with a BizSolutions representative, followed by an email confirmation from the client.</li>
                    </ul>
                  </Card.Subtitle>
                </div>

                <div className='mb-4'>
                  <Card.Subtitle className="mb-2"><p>3. Non-Cancellable Services</p>
                    The following services cannot be canceled or refunded once initiated:
                    <ul>
                        <li>Custom software or application development</li>
                        <li>Training and upskilling services</li>
                        <li>Prepaid or discounted service packages</li>
                        <li>All outsourced services with an active contract</li>
                    </ul>
                  </Card.Subtitle>
                </div>

                <div className='mb-4'>
                  <Card.Subtitle className="mb-2"><p>4. Post-Sale Digital & Verbal Approval Requirement</p>
                    To ensure proper acknowledgment after the sale, all clients must confirm their understanding of this cancellation policy using one of the following methods:
                    <ul>
                        <li>Digital Approval: Clients will receive an email confirmation or an online acknowledgment form after purchase, which they must accept before service begins.</li>
                        <li>Verbal Approval: Clients purchasing services over the phone will receive a recorded confirmation call stating that they acknowledge and accept this cancellation policy.</li>
                    </ul>
                    By providing post-sale approval digitally or verbally, the client waives any right to dispute or reverse applicable fees and cancellation terms.
                  </Card.Subtitle>
                </div>

                <div className='mb-4'>
                  <Card.Subtitle className="mb-2"><p>5. Cancellation Fees & Penalties</p>
                    <ul>
                        <li>Annual & Monthly Subscriptions: If a client cancels before the agreed-upon term, they will be charged 50% of the remaining contract value for the current annual or monthly subscription.</li>
                        <li>Administrative Fee: This will be determined on a case-by-case basis and will be 50% of the current monthly or annual rate.</li>
                    </ul>
                  </Card.Subtitle>
                </div>

                <div className='mb-4'>
                  <Card.Subtitle className="mb-2"><p>6. Chargeback & Bank Protection Clause</p>
                    <ul>
                        <li>All payments are final and non-disputable after 7 days of invoice issuance.</li>
                        <li>By engaging in our services, clients agree not to dispute charges with their bank, except in cases of proven fraud.</li>
                        <li>Any chargeback attempts will result in immediate legal action and reporting to collections agencies.</li>
                    </ul>
                  </Card.Subtitle>
                </div>

                <div className='mb-4'>
                  <Card.Subtitle className="mb-2"><p>7. Cancellation Approval & Enforcement</p>
                    <ul>
                        <li>BizSolutions LLC reserves the right to approve or deny cancellation requests based on contract terms and service usage.</li>
                        <li>If cancellation is denied, the client remains liable for all outstanding balances.</li>
                    </ul>
                  </Card.Subtitle>
                </div>

                <div className='mb-4'>
                  <Card.Subtitle className="mb-2"><p>8. Force Majeure Clause</p>
                    In cases of natural disasters, government restrictions, or other uncontrollable events, BizSolutions will review cancellations on a case-by-case basis but retains the right to enforce contractual obligations.
                  </Card.Subtitle>
                </div>

                <div className='mb-4'>
                  <Card.Subtitle className="mb-2"><p>Client Acknowledgment</p>
                    By engaging with BizSolutions LLC, clients acknowledge and accept this cancellation policy. Approval through digital means or a recorded verbal confirmation after purchase serves as a legally binding agreement.
                  </Card.Subtitle>
                </div>

              </Card.Body>
          </Card>
        </Container>
      </div>

      <Footer />
    </>
  );
}
