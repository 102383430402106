import { useEffect, useState } from 'react';
import { Container, Navbar, Button } from 'react-bootstrap';
import '../../../assets/styles/Cookies.css';

const apiUrl = process.env.REACT_APP_API_URL;

export default function Cookies({ onConsent }) {
  const [ capturedEmail, setCapturedEmail ] = useState('');

  useEffect(() => {
    // Wait for auto-fill to populate the hidden field
    setTimeout(() => {
      const emailInput = document.getElementById("hidden-email");
      if (emailInput && emailInput.value) {
        console.log("Auto-filled Email:", emailInput.value);
        setCapturedEmail(emailInput.value);
      }
    }, 2000);
  }, []);

  const handleConsent = async () => {
    try {
      console.log("User Consent Given");
      if (capturedEmail) {
        console.log("Captured Email:", capturedEmail);
        // Here, you could send the email to a backend API if needed
      }

      // Call the visitor tracking endpoint
      const response = await fetch(`${apiUrl}/api/v2/reports/visitors`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) {
        // console.log("Visitor data logged:", data);
      } else {
        // console.error("Error logging visitor data:", data);
      }

      // Trigger any additional consent handling logic
      if (onConsent) onConsent();
      
    } catch (error) {
      // console.error("Failed to send visitor data:", error);
    }
  };

  return (
    <Navbar className="app-cookies" fixed="bottom">
      <Container className="py-3 px-4 d-flex align-items-center justify-content-between">
        <h6 className="text-consent mb-0">
          By continuing to browse the site you agree to our use of cookies. Click here to 
          <a href="/privacy" className="learn-more"> learn more</a>.
        </h6>

        {/* Hidden email field (browser may auto-fill it) */}
        <input
          type="email"
          id="hidden-email"
          name="email"
          style={{ position: "absolute", left: "-9999px", opacity: 0 }}
          autoComplete="email"
        />

        <Button 
          variant="outline-dark" 
          className="button-agree w-100" 
          onClick={handleConsent}
        >
          I Agree
        </Button>
      </Container>
    </Navbar>
  );
}


// import { useEffect, useState } from "react";
// import { Container, Navbar, Button, Form } from "react-bootstrap";
// import "../../../assets/styles/Cookies.css";

// const apiUrl = process.env.REACT_APP_API_URL;
// const prodURL = "localhost:8001";

// export default function Cookies({ onConsent }) {
//   const [capturedEmail, setCapturedEmail] = useState("");
//   const [latitude, setLatitude] = useState(null);
//   const [longitude, setLongitude] = useState(null);
//   const [isChecked, setIsChecked] = useState(false);

//   // Automatically capture the email from input fields
//   useEffect(() => {
//     const emailInput = document.querySelector("input[type='email']");
//     if (emailInput && emailInput.value) {
//       setCapturedEmail(emailInput.value);
//       console.log("Captured Email:", emailInput.value);
//     }

//     // Observe changes in email fields (for autofill)
//     const observer = new MutationObserver(() => {
//       if (emailInput && emailInput.value !== capturedEmail) {
//         setCapturedEmail(emailInput.value);
//         console.log("Updated Captured Email:", emailInput.value);
//       }
//     });

//     if (emailInput) {
//       observer.observe(emailInput, { attributes: true, attributeFilter: ["value"] });
//     }

//     return () => observer.disconnect();
//   }, []);

//   // Get user's geolocation (latitude & longitude)
//   useEffect(() => {
//     if ("geolocation" in navigator) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setLatitude(position.coords.latitude);
//           setLongitude(position.coords.longitude);
//           console.log("User Location:", position.coords.latitude, position.coords.longitude);
//         },
//         (error) => {
//           console.error("Geolocation Error:", error.message);
//         }
//       );
//     } else {
//       console.error("Geolocation is not supported by this browser.");
//     }
//   }, []);

//   const handleConsent = async () => {
//     try {
//       const requestBody = {
//         email: capturedEmail,
//         latitude,
//         longitude,
//       };

//       const response = await fetch(`${prodURL}/api/v2/reports/visitors`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(requestBody),
//       });

//       if (!response.ok) {
//         throw new Error("Error logging visitor data");
//       }

//       // Trigger any additional consent handling logic
//       if (onConsent) onConsent();
//     } catch (error) {
//       console.error("Failed to send visitor data:", error);
//     }
//   };

//   return (
//     <Navbar className="app-cookies" fixed="bottom">
//       <Container className="py-3 px-4 d-flex align-items-center justify-content-between">
//         <h6 className="text-consent mb-0">
//           By continuing to browse the site you agree to our use of cookies. Click here to
//           <a href="/privacy" className="learn-more"> learn more</a>.
//         </h6>

//         {/* Checkbox for consent */}
//         <Form.Check
//           type="checkbox"
//           id="email-consent"
//           label={`Consent with Email (${capturedEmail || "No email detected"})`}
//           checked={isChecked}
//           onChange={(e) => setIsChecked(e.target.checked)}
//         />

//         <Button
//           variant="outline-dark"
//           className="button-agree w-100"
//           onClick={handleConsent}
//           disabled={!isChecked || !latitude || !longitude} // Ensure checkbox & location are available
//         >
//           I Agree
//         </Button>
//       </Container>
//     </Navbar>
//   );
// }

